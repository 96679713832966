import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import PurchaseSummary from './PurchaseSummary/PurchaseSummary';
import Ad from './Ad/Ad';
import NeedHelpSection from 'styleguide/components/NeedHelpSection/NeedHelpSection';
import SimplyShip from './SimplyShip/SimplyShip';
import OrderContext from 'contexts/OrderContext/OrderContext';
import ProductsTable from 'styleguide/components/ProductsTable/ProductsTable';
import Modal from 'styleguide/components/Modal/Modal';
import Button from 'styleguide/components/Button/Button';
import { postCheckout } from 'api/cartPage';
import { checkout } from 'api/gtm';
import { Status } from 'libs/utils/api/types';
import toasts from 'utils/toast';
import { useHistory } from 'react-router';
import SimplyShipContextContainer from 'contexts/SimplyShipContext/SimplyShipContextContainer';
import {
  addUploadToFileVersion,
  doRemoveLineItem,
  doUpdateLineItem,
  removeUploadToFileVersion,
  setOrder,
} from 'contexts/OrderContext/actions';
import TempOrderTotalsContext from 'contexts/TempOrderTotalsContext/TempOrderTotalsContext';

const MiddleSection = () => {
  const history = useHistory();
  const { order, dispatch } = React.useContext(OrderContext);
  const tempOrderTotalsContext = React.useContext(TempOrderTotalsContext);
  const [showPromo, setShowPromo] = React.useState(false);
  const [showCheckoutConfirmation, setShowCheckoutConfirmation] = React.useState(false);

  const onCheckoutClick = () => {
    checkout(
      { step: order.state, currency: 'USD', value: order.subtotal },
      order.lineItems.map(elem => ({
        item_name: elem.product.name,
        item_id: elem.product.slug,
        price: parseFloat(elem.total) / elem.quantity,
        quantity: elem.quantity,
      })),
    ).then(() => {
      postCheckout().then(res => {
        if (res.status === Status.Ok) {
          setOrder(dispatch, res.payload);
          history.push('/checkout');
        } else {
          toasts.error();
        }
      });
    });
  };

  return (
    <Grid.Container className="!md:mt-16 !md:p-0 !mt-8 !px-4">
      <Grid noPadding>
        <Grid.Row className="!my-0 !ml-4 !mr-0 !p-0">
          <Grid.Col className="!py-0 !pl-0 !pr-[5vw] -md:!pr-0" sm={12} md={8}>
            <SimplyShipContextContainer
              admin={false}
              save={false}
              order={order}
              dispatch={dispatch}
              setOrderTotals={tempOrderTotalsContext.setOrderTotals}
              cartPage
            >
              <SimplyShip />
            </SimplyShipContextContainer>
            <ProductsTable
              order={order}
              isLoading={order.status === 'loading'}
              canEdit
              canUpload
              doRemoveLineItem={doRemoveLineItem}
              doUpdateLineItem={doUpdateLineItem}
              onRemoveUpload={removeUploadToFileVersion}
              onAddUpload={addUploadToFileVersion}
              dispatch={dispatch}
            />
            <Ad />
          </Grid.Col>
          <Grid.Col className="!p-0" sm={12} md={4}>
            {!!showCheckoutConfirmation && (
              <Modal className="!max-w-2xl" shown onClose={() => setShowCheckoutConfirmation(false)}>
                <Grid.Container className="pb-[10px]">
                  <Grid>
                    <Grid.Row>
                      <Grid.Col className="flex flex-col items-start justify-center" sm={12}>
                        <div data-cy="forgotFilesModalTitle" className="heading-bold-xs">
                          Forgot to upload your files?
                        </div>
                        <div data-cy="forgotFilesModalContent" className="paragraph-desktop my-6 text-left">
                          To avoid delaying your order, please upload files on the previous screen before
                          proceeding. If you&apos;re not ready to upload, you can pay for your order and
                          upload later. Or, if you&apos;d like help designing your file, continue placing your
                          order, and during checkout, be sure to mention our Graphic Design Services in the
                          Special Instructions box - we&apos;ll get back to you with a quote shortly!
                        </div>
                        <div className="flex flex-col md:flex-row md:justify-end">
                          <Button
                            dataCy="forgotFilesReturnToCartBtn"
                            className="md:mr-6 md:!h-[47px] -md:mb-6"
                            type="button"
                            color="blue"
                            size="md"
                            onClick={() => setShowCheckoutConfirmation(false)}
                          >
                            Return To Cart
                          </Button>
                          <Button
                            dataCy="forgotFilesProceedBtn"
                            type="button"
                            color="dark"
                            outline
                            size="md"
                            onClick={onCheckoutClick}
                          >
                            Proceed Anyway
                          </Button>
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid>
                </Grid.Container>
              </Modal>
            )}
            <div className="sticky top-32 flex flex-col">
              <PurchaseSummary
                onCheckoutClick={() => {
                  const missingUploads =
                    order.lineItems.filter(li => li.fileVersions[0].uploads.length === 0 && li.filesRequired)
                      .length > 0;

                  if (missingUploads) {
                    setShowCheckoutConfirmation(true);
                  } else {
                    onCheckoutClick();
                  }
                }}
                showPromoCodeField={() => setShowPromo(true)}
                showPromo={showPromo}
                promotions={order.promotions}
                buttonText="Checkout"
                checkoutDisabled={order.lineItems.length === 0}
              />
              <NeedHelpSection />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Grid.Container>
  );
};
export default MiddleSection;
