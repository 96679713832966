import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import NeedHelpSection from 'styleguide/components/NeedHelpSection/NeedHelpSection';
import PurchaseSummary from 'bundles/App/pages/CartPage/MiddleSection/PurchaseSummary/PurchaseSummary';
import Ad from 'bundles/App/pages/CartPage/MiddleSection/Ad/Ad';
import { H6 } from 'styleguide/components/Heading';
import { Link } from 'styleguide/components';

const EmptyCart = () => (
  <Grid.Container className="!md:mt-16 !md:p-0 !mt-8 !px-4">
    <Grid noPadding>
      <Grid.Row className="!my-0 !ml-4 !mr-0 !p-0">
        <Grid.Col className="!py-0 !pl-0 !pr-[5vw] -md:!pr-0" sm={12} md={8}>
          <H6 data-cy="yourCartIsEmptyText" className="!mt-4">
            Your cart is empty. please click{' '}
            <Link data-cy="yourCartIsEmptyProductsLink" to="/products" color="blue" underline="active">
              here{' '}
            </Link>
            to select a product
          </H6>
          <Ad />
        </Grid.Col>
        <Grid.Col className="!p-0" sm={12} md={4}>
          <PurchaseSummary showPromo={false} promotions={[]} checkoutDisabled buttonText="Checkout" />
          <NeedHelpSection />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default EmptyCart;
